
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$RateLoc-primary: mat.define-palette(mat.$indigo-palette);
$RateLoc-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$RateLoc-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$RateLoc-theme: mat.define-light-theme((
  color: (
    primary: $RateLoc-primary,
    accent: $RateLoc-accent,
    warn: $RateLoc-warn,
  )
));

:root {
	/* Colors */
	--primary-color: #EC6408;
	--secondary-color: #ffb400;
	--tertiary-color: #4a6660;
	--light-purple-color: #a3adcc;
	--blue-color: #2275ff;
	--red-color: #fe455f;
	--yellow-color: #faba2f;
	--green-color: #51d77a;
	--very-dark-gray-color: #252525;
	--dark-gray-color: #2d3434;
	--medium-gray-color: #6c7171;
	--light-gray-gray: #a8b1b5;
	--anti-flash-white-color: #f2f4f7;

	/* Social Icons Colors */
	--facebook-color: #1877f2;
	--twitter-color: #3aacf3;
	--linkedin-color: #3291c2;
	--youtube-color: #ff0000;
	--instagram-color: #e1306c;


	/* Background Colors */

	/* Gardients */

	/* Font Family */
	--heading-font-family-main: 'Gilroy_Bold', sans-serif;
	--body-font-family: 'Open Sans', sans-serif;

	/* Font Sizes */
	--font-size-xxx-large: 96px;
	--font-size-xx-large: 72px;
	--font-size-x-large: 48px;
	--font-size-large: 30px;
	--font-size-medium: 24px;
	--font-size-small: 20px;
	--font-size-x-small: 18px;
	--font-size-xx-small: 12px;
	--font-size-xxx-small: 10px;
	
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($RateLoc-theme);

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '~font-awesome/css/font-awesome.min.css';
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

$main-color: #ec6408;

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.fontMuli{
  font-family: 'Muli', sans-serif;
}

.black-color{
  color: #1C2023;
}

.orange-color{
  color: #EC6408;
}

.bg-orange-color{
	background-color: #EC6408;
}


.main-button {
    color: white !important;
    text-transform: uppercase;
    text-decoration: none;
    background: #ec6408 !important;
    padding: 15px;
    border-radius: 10px;
    display: inline-block;
    width: 150px;
    transition: all 0.4s ease 0s;
}

.main-button:hover {
    color: #ffffff !important;
    background: #ec6408 !important;
    border-color: white !important;
    // transform: scale(0.9); /* Zoom effect on hover */
	box-shadow: 0 0 20px 0px rgba(0,0,0,0.2);
    transition: all 0.4s ease 0s !important;
}


/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

@import 'ngx-toastr/toastr';

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
